import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { LuppCanceledTomorrow } from '@/types/lupp-canceled-tomorrow'

interface FetchParams {
  from: string
  to: string
}

export function getCanceledTomorrow(
  params: FetchParams
): Promise<AxiosResponse<LuppCanceledTomorrow[]>> {
  return instance.get(`/lupp/planned-cancellation`, {
    params,
  })
}
