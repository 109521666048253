import { render } from "./CanceledTomorrowTable.vue?vue&type=template&id=67430f4a"
import script from "./CanceledTomorrowTable.vue?vue&type=script&lang=ts"
export * from "./CanceledTomorrowTable.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./CanceledTomorrowTable.vue?vue&type=style&index=0&id=67430f4a&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QSpace,QInput,QIcon,QBtn});
