
import { defineComponent } from 'vue'
import CanceledTomorrowTable from '@/components/tools/canceled-tomorrow/CanceledTomorrowTable.vue'

export default defineComponent({
  name: 'VehicleWithdrawal',

  components: {
    CanceledTomorrowTable,
  },

  setup() {
    return {}
  },
})
