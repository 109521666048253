<template>
  <q-page>
    <CanceledTomorrowTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CanceledTomorrowTable from '@/components/tools/canceled-tomorrow/CanceledTomorrowTable.vue'

export default defineComponent({
  name: 'VehicleWithdrawal',

  components: {
    CanceledTomorrowTable,
  },

  setup() {
    return {}
  },
})
</script>
