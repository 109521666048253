import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { getCanceledTomorrow } from '@/api/lupp/getCanceledTomorrow'
import { LuppCanceledTomorrow } from '@/types/lupp-canceled-tomorrow'

interface FetchParams {
  from: string
  to: string
}

interface useCanceledTomorrow {
  data: Ref<LuppCanceledTomorrow[]>
  fetchAll: (params: FetchParams) => Promise<LuppCanceledTomorrow[]>
  loading: ComputedRef<Loading>
}

const data = ref<LuppCanceledTomorrow[]>([])
const { state } = useLoading()

export function useCanceledTomorrow(): useCanceledTomorrow {
  async function fetchAll(params: { from: string; to: string }) {
    state.getAll = true
    return new Promise<LuppCanceledTomorrow[]>((resolve, reject) => {
      getCanceledTomorrow(params)
        .then(({ data: canceledTomorrow }) => {
          data.value = canceledTomorrow
          resolve(canceledTomorrow)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  return {
    data,
    fetchAll,
    loading: computed(() => state),
  }
}
