<template>
  <q-table
    dense
    :class="$style.stickyHeader"
    :loading="loading.getAll"
    :filter="filterText"
    :rows-per-page-options="[0]"
    :rows="canceledTomorrow"
    :columns="columns"
    row-key="uuid"
    style="height: calc(100vh - 50px - 68px)"
    flat
  >
    <template v-slot:top>
      <h6 class="q-ma-none">Planerat inställda tåg</h6>

      <q-space />

      <AppFieldDateRange
        :square="false"
        dense
        range
        v-model="period"
        class="q-mr-sm"
      />

      <q-input
        style="width: 100%; max-width: 300px"
        debounce="300"
        v-model="filterText"
        placeholder="Sök..."
        dense
        outlined
      >
        <template v-slot:append>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>

      <q-btn
        :loading="exporting"
        @click="onExport"
        class="q-ml-md"
        icon-right="mdi-file-export-outline"
        label="Export"
        color="grey-7"
        outline
      />
    </template>
  </q-table>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { QTable } from 'quasar'
import { format, addDays } from 'date-fns'
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useCanceledTomorrow } from '@/composable/useCanceledTomorrow'
import { LuppCanceledTomorrow } from '@/types/lupp-canceled-tomorrow'
import AppFieldDateRange from '@/components/AppFieldDateRange.vue'

export default defineComponent({
  name: 'CanceledTomorrowTable',

  components: {
    AppFieldDateRange,
  },

  setup() {
    const {
      fetchAll: fetchAllCanceledTomorrow,
      data: canceledTomorrow,
      loading,
    } = useCanceledTomorrow()
    const { can } = useProfile()
    const exporting = ref(false)

    const period = ref({
      from: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    })

    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'date',
        label: 'Datum',
        field: 'date',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        align: 'left',
        name: 'announced',
        label: 'Tåguppdrag',
        field: 'announced',
        sortable: true,
      },
      {
        align: 'left',
        name: 'track',
        label: 'Spår',
        field: 'track',
        sortable: true,
      },
      {
        align: 'left',
        name: 'from',
        label: 'Från',
        field: 'from',
        sortable: true,
      },
      {
        align: 'left',
        name: 'to',
        label: 'Till',
        field: 'to',
        sortable: true,
      },
      {
        align: 'left',
        name: 'canceled',
        label: 'Inställt',
        field: 'canceled',
        sortable: true,
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd'),
      },
      {
        align: 'left',
        name: 'reason',
        label: 'Orsak',
        field: 'reason',
        sortable: true,
      },
      {
        align: 'left',
        name: 'canceledReason',
        label: 'Orsak beskrivning',
        field: (v: LuppCanceledTomorrow) => v.canceledReason.description,
        sortable: true,
      },
    ]

    async function onExport() {
      exporting.value = true

      const exportData = {
        name: 'Planerat inställda tåg',
        sheets: [
          {
            name: 'Planerat inställda tåg',
            data: canceledTomorrow.value.map((item) => {
              return columns?.reduce<{ [name: string]: unknown }>(
                (acc, col) => {
                  const value =
                    typeof col.field === 'function'
                      ? col.field(item)
                      : item[col.name as keyof LuppCanceledTomorrow]

                  if (col.format) {
                    acc[col.label] = col.format(value, null)
                  } else {
                    acc[col.label] = value
                  }

                  return acc
                },
                {}
              )
            }),
          },
        ],
      }

      exportExcel(exportData)
      exporting.value = false
    }

    watch(period, fetchAllCanceledTomorrow, {
      immediate: true,
    })

    return {
      columns,
      loading,
      canceledTomorrow,
      exporting,
      can,
      onExport,
      period,
      filterText: ref(''),
    }
  },
})
</script>

<style lang="scss" module>
.stickyHeader {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
